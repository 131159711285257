import React, { useEffect, useState } from "react";
import {  useHistory } from "react-router";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
    BsLightningFill,
} from "react-icons/bs";
import { FaAward,  FaColumns } from "react-icons/fa";
import { ImagePopup } from "../widgets/tiles/ImagePopUp";
import { VideoPopup } from "../widgets/tiles/VideoPopUp";
import PropTypes from "prop-types";
import { ConfirmGoods,HoldPopup,SwalWarn, SwalError,CompareDiamond} from "../Popup";
import {
    makeStyles,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Checkbox,
    TableSortLabel,
} from "@material-ui/core";
import axios from "axios";
// import moment from "moment-timezone";

import { FaArrowAltCircleRight, FaArrowCircleDown } from "react-icons/fa";
import MuiExpandedWishlist from "./MuiExpandedWishlist";
import { FETCH_WISHLIST, DELETE_WISHLIST } from "../../../env_config";
import { NODE_API_URL } from "../../../env_config";
// StockAPI = StockAPI.slice(0,25)
const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
});
function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function GetRatio(row) {
    let $ratioval
    if (row.C_Shape !== 'ROUND') {
        $ratioval = (row.C_Length / row.C_Width).toFixed(2);
    } else {
        $ratioval = '-';
    }
    return $ratioval
}
function Row(props) {
    const {
        row,
        isItemSelected,
        handleClick,
        labelId,
        conversionrate,
        symbol,
        language,BuyDiamond,HoldDiamond
    } = props;
    const [open, setOpen] = React.useState(false);
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);

    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow
                className={`${classes.root} ${
                    row.diamond_type === "N" ? "natTab" : "labTab"
                }`}
                hover
                // onClick={(event) => handleClick(event, row.ProductId)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
            >
             <TableCell padding="checkbox" align="center">
                    <Checkbox
                        checked={isItemSelected}
                        className="py-0"
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(event) => handleClick(event, row.id)}
                    />
            </TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <FaArrowCircleDown
                                className="text-success"
                                title="Collapse"
                            />
                        ) : (
                            <FaArrowAltCircleRight
                                className="text-primary"
                                title="Expand"
                            />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell scope="row" className="text-nowrap">
                    <span className="text-primary">
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {
                                    row.availability === 'Memo' || row.availability === 'Hold'
                                        ? `Business Process`
                                        : row.availability === "Guaranteed"
                                        ? `Guaranteed`
                                        : row.availability === "Not Available"
                                        ? `Not Available`
                                        : row.availability === "Subject to Prior sale"
                                        ? `Subject to Prior sale`
                                        : `Unknown`
                                    }
                                </Tooltip>
                            }
                        >
                            <img
                                className="img-fluid availIco mr-2"
                                alt={row.availability}
                                src={toAbsoluteUrl(`/media/svg/${
                                    row.availability === 'Memo' || row.availability === 'Hold'
                                        ? `availability_primary.svg`
                                        : row.availability === "Guaranteed"
                                        ? `availability_success.svg`
                                        : row.availability === "Not Available"
                                        ? `availability_danger.svg`
                                        : row.availability === "Subject to Prior sale"
                                        ? `availability_warning.svg`
                                        : `availability_blank_white.svg`
                                }`)}
                            />
                           
                        </OverlayTrigger>
                    </span>
                        {row.diamond_type === "N" ? "N" : "L"}-{row.id}
                    </span>
                </TableCell>
                <TableCell className={"text-nowrap"} align="center">
                    {row.created_date.split('T')[0]}
                    {/* { row.created_date ? moment.tz(row.created_date, props.userinfo.timezone ? props.userinfo.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"}  */}
                </TableCell>
                <TableCell align="center">
                    {row.stone_status === 1? <div className="label label-outline-success label-pill label-inline bg-success-o-30 px-5">In Stock</div>:<div className="label label-outline-danger label-pill label-inline bg-danger-o-30 px-5">Sold Out</div>}
                </TableCell>
                <TableCell align="center">
                    {row.country ? (
                        <OverlayTrigger
                            placement="top"
                            className="text-capitalize"
                            overlay={
                                <Tooltip id="tooltip">{row.country}</Tooltip>
                            }
                        >
                            <img
                                className="flagIco mr-1"
                                alt={row.value}
                                src={toAbsoluteUrl(
                                    `/media/map/${row.country.toLowerCase()}.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src =toAbsoluteUrl(
                                        `/media/map/other.png`
                                        )
                                    }}
                            />
                        </OverlayTrigger>
                    ) : null}
                </TableCell>
                <TableCell align="center" className="text-nowrap">
                    <div className="d-flex justify-content-between">
                        {row.aws_image ? (
                            <span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">Image</Tooltip>
                                    }
                                >
                                    <ImagePopup
                                        Image={row.aws_image}
                                        Shape={row.C_Shape}
                                    />
                                    {/* <a className="text-primary mx-2 text-center" href={row.image}><BsCardImage/></a> */}
                                </OverlayTrigger>
                            </span>
                        ) : (
                            <span style={{ width: "27px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        )}
                        {row.video ? (
                            <span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">Video</Tooltip>
                                    }
                                >
                                    <VideoPopup
                                        ProductId={row.id}
                                        WLDiamondType={row.diamond_type}
                                        Certi_No={row.Certi_NO}
                                        Video={row.video}
                                        src={row.ext_video}
                                        data={props.StockAPI}
                                    />
                                    {/* <a className="text-primary mx-2 text-center" href={row.video}><BsFillCameraVideoFill/></a> */}
                                </OverlayTrigger>
                            </span>
                        ) : (
                            <span style={{ width: "27px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        )}
                        <span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Certificate
                                        </Tooltip>
                                    }
                                >
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-primary mx-2 text-left"
                                        href={
                                            row.Lab === "IGI"
                                                ? `https://www.igi.org/verify-your-report/?r=LG${row.Certi_NO}`
                                                : row.Lab === "GIA"
                                                ? `https://www.gia.edu/report-check?reportno=${row.Certi_NO}`
                                                : row.Lab === "HRD"
                                                ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${row.Certi_NO}`
                                                : row.Lab === "GCAL"
                                                ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${row.Certi_NO}`
                                                : row.Certi_link
                                        }
                                    >
                                        <FaAward />
                                    </a>
                                </OverlayTrigger>
                            </span>
                    </div>
                </TableCell>
                <TableCell align="center">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip">{row.C_Shape}</Tooltip>}
                    >
                        <SVG
                            width="25px"
                            height="25px"
                            src={toAbsoluteUrl(
                                `/media/shape/svg_shape/${row.C_Shape.toLowerCase()}.svg`
                            )}
                        />
                    </OverlayTrigger>
                </TableCell>
                <TableCell align="left">{row.C_Weight.toFixed(2)}</TableCell>
                <TableCell align="center">
                    <OverlayTrigger placement="top" overlay=
                        {<Tooltip id="tooltip" >
                            {row.C_Color && row.C_Color.toLowerCase() ==="fancy" ? ( <> {row.f_intensity} {row.f_overtone} {row.f_color} </> ) : ( row.C_Color )}
                        </Tooltip>} >
                        <span className="d-inline-block text-truncate text-dark" style={{ maxWidth: "80px"}}>
                            {row.C_Color && row.C_Color.toLowerCase() ==="fancy" ? ( <> {row.f_intensity} {row.f_overtone} {row.f_color} </> ) : ( row.C_Color )}
                        </span>
                  </OverlayTrigger>
                </TableCell>
                <TableCell align="center">{row.C_Clarity}</TableCell>
                <TableCell align="center">
                    {row.C_Cut ? row.C_Cut : "-"}
                </TableCell>
                <TableCell align="center">{row.C_Polish}</TableCell>
                <TableCell align="center">{row.C_Symmetry}</TableCell>
                <TableCell align="center">{row.C_Fluorescence}</TableCell>
                <TableCell align="center">{row.Lab}</TableCell>
                <TableCell className="text-nowrap" align="center">
                    {
                        symbol !=="$" ? <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        ${numberWithCommas(row.OurRate)}
                                    </Tooltip>
                                }
                            >
                                <span> {symbol}{numberWithCommas(row.OurRate * conversionrate)}</span>
                            </OverlayTrigger>
                        </> :
                        <span> {symbol}{numberWithCommas(row.OurRate * conversionrate)}</span>
                    }
                </TableCell>
                <TableCell className="text-nowrap" align="center">
                    {
                        symbol !=="$" ? <>
                           <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        ${numberWithCommas(row.OurPrice)}
                                    </Tooltip>
                                }
                            >
                                <span> {symbol}{numberWithCommas(row.OurPrice * conversionrate)}</span>
                            </OverlayTrigger>
                        </> :
                        <span> {symbol}{numberWithCommas(row.OurPrice * conversionrate)}</span>
                    }
                </TableCell>
                <TableCell align="center">
                    {row.discount_main ? row.discount_main : "0.00"}%
                </TableCell>
                <TableCell align="center">
                {/* {row.Ratio?row.Ratio.toFixed(2):"-"}{" "} */}
                {row.Ratio?row.Ratio.toFixed(2):"-"}{" "}
                </TableCell>
                <TableCell align="center" className="text-nowrap">
                    {row.C_Length}*{row.C_Width}*{row.C_Depth}
                </TableCell>
                <TableCell align="center">{row.C_TableP}%</TableCell>
                <TableCell align="center">{row.C_DefthP}%</TableCell>
                <TableCell align="center">
                <a
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary mx-2 text-left"
                            href={
                                row.Lab === "IGI"
                                    ? `https://www.igi.org/verify-your-report/?r=LG${row.Certi_NO}`
                                    : row.Lab === "GIA"
                                    ? `https://www.gia.edu/report-check?reportno=${row.Certi_NO}`
                                    : row.Lab === "HRD"
                                    ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${row.Certi_NO}`
                                    : row.Lab === "GCAL"
                                    ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${row.Certi_NO}`
                                    : row.Certi_link
                            }
                        >
                            {row.Certi_NO}
                        </a>
                </TableCell>
            </TableRow>
            <MuiExpandedWishlist
                row={row}
                open={open}
                conversionrate={conversionrate}
                symbol={symbol}
                language={language}
                handleClick={handleClick} totalinrcar={props.totalinrcar} totalourprice={props.totalourprice} diamondselected={props.diamondselected} buypopup={props.buypopup} BuyDiamond={BuyDiamond} HoldDiamond={HoldDiamond}
            />
        </React.Fragment>
    );
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        symbol,
        language,
        isActive
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    const headCells = [
        { id: "", numeric: false, disablePadding: true, label: "" },
        { id: "id", numeric: false, disablePadding: true, label: language.SEARCHLIST_STOCKID},
        {
            id: "created_date",
            numeric: false,
            disablePadding: false,
            label: language.WISHLIST_DATE,
        },
        {
            id: "Status",
            numeric: false,
            disablePadding: false,
            label: language.WISHLIST_STATUS,
        },
        {
            id: "country",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_LOCATION,
        },
        { id: "image", numeric: false, disablePadding: false, label: language.SEARCHLIST_MEDIA },
        {
            id: "C_Shape",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_SHAPE,
        },
        {
            id: "C_Weight",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_CARAT,
        },
        {
            id: "C_Color",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_COLOR,
        },
        {
            id: "C_Clarity",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_CLARITY,
        },
        { id: "C_Cut", numeric: false, disablePadding: false, label: language.SEARCHLIST_CUT },
        {
            id: "C_Polish",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_POL,
        },
        {
            id: "C_Symmetry",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_SYMM,
        },
        {
            id: "C_Fluorescence",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_FLOU,
        },
        { id: "Lab", numeric: false, disablePadding: false, label: language.SEARCHLIST_LAB },
        {
            id: "OurRate",
            numeric: true,
            disablePadding: false,
            label: `${symbol === "₹" ? "INR" : symbol}/CT`,
        },
        {
            id: "OurPrice",
            numeric: true,
            disablePadding: false,
            label: language.SEARCHLIST_OURPRICE,
        },
        { id: "discount_main", numeric: false, disablePadding: false, label: language.SEARCHLIST_DISC },
        { id: "Ratio", numeric: false, disablePadding: false, label: language.SEARCHLIST_RATIO },
        {
            id: "C_Length",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_MEAS,
        },
        {
            id: "C_TableP",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_TABLE,
        },
        {
            id: "C_DefthP",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_DEPTH,
        },
        {
            id: "Certi_NO",
            numeric: false,
            disablePadding: false,
            label: language.SEARCHLIST_CERTIFICATE,
        },
    ];
    return (
        <TableHead className="searchTableHeader">
            <TableRow>
                <TableCell padding="checkbox">
                    {isActive ?
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                    /> : null }
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={"center"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));
export const Wishlist = ({ conversionrate, symbol ,language,isprofilecomplete,userinfo }) => {
    const history = useHistory();
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState();
    const [selected, setSelected] = React.useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    // console.log(conversionrate, "conversionrate");
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()

    const [totalcarat, setTotalCarat] = useState(0);
    const [totalinrcar, setTotalInrCar] = useState(0);
    const [totalourprice, setTotalOurPrice] = useState(0);
    const [diamondselected,setDiamondSelected] = useState([])
    const [buypopup,setBuyPopUp] = useState(false)
    const [comparePopup,setComparePopup] = useState(false)
    const [holdpopup,setHoldPopUp] = useState(false)
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
    const [diamondcount,setDiamondcount] = useState(0)
    const [buypopupctotal,setBuyPopUpTotal] = useState(0)
    const [StockAPI, setStockAPI] = useState(null);
    const [FirstPage,setFirstPage] = useState([])
    const [StockCount,setStockCount] = useState(0);
    const [updatewishlist, setUpdateWishlist] = useState();
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    useEffect(() => {
        let temptotalcarat = 0;
        let temptotalinrcar = 0;
        let temptotalourprice = 0;
        selected.map((value) => {
            const finddata = StockAPI.find(
                (element) => element.id.toString() === value.toString()
            );
            if (finddata) {
                temptotalcarat += finddata.C_Weight;
                temptotalinrcar += (selected.length>0?finddata.OurRate/selected.length:0);
                temptotalourprice += finddata.OurPrice;
            }
        });
        setTotalCarat(temptotalcarat);
        setTotalInrCar(selected.length>0?(temptotalourprice/temptotalcarat * conversionrate):0);
        setTotalOurPrice(temptotalourprice * conversionrate);
    }, [selected]);
    const [isActive, setIsActive] = React.useState(true);
    

    // const [StockAPI, setStockAPI] = useState(location.state);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    useEffect(() => {
        const finalData = {
            user_id: user.CustomerId,
            page:page + 1,
            orderBy:orderBy,
            order:order
        };
        axios({
            method: "POST",
            url: `${NODE_API_URL}/IVP/fetchWishlist`,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then((res) => {
                if (res && res.data && res.data.data) {
                    // setData(res.data)
                    setStockAPI(res.data.data);
                    if((page + 1) === 1){
                        setFirstPage(res.data.data)
                    }
                    // res.data[0].map(value => {
                    //     if (value.stone_status === "Inacti"){
                    //         setIsActive(false)
                    //     }
                    // })  
                }
            })
            .catch((err) => {
            });
            axios({
                method: "POST",
                url: `${NODE_API_URL}/IVP/fetchWishlistCount`,
                headers: { "Content-Type": "application/json" },
                data: finalData,
            })
                .then((res) => {
                    if (res && res.data && res.data.count) {
                        // setData(res.data)
                        setStockCount(res.data.count);
                        // res.data[0].map(value => {
                        //     if (value.stone_status === "Inacti"){
                        //         setIsActive(false)
                        //     }
                        // })  
                    }
                })
                .catch((err) => {
                });
    }, [updatewishlist,page,order,orderBy]);
    // console.log(rows.length, "rows");
    const handleChangePage = (event, newPage) => {
        console.log(newPage,"newPage")
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }
    const profileSwalBuy = () => {
        // setSemiAprroveModal(true)
        setSwalErrorOpen(true)
        setAlertMessage("Please complete your profile to buy more diamonds")
    }
    const profileSwalHold = () => {
        // setSemiAprroveModal(true)
        setSwalErrorOpen(true)
        setAlertMessage("Please complete your profile to hold more diamonds")
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = StockAPI.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };
    const CompareDiam = (selectedFlatRows) => {
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }
        if(selectedFlatRows.length === 1){
            alert("Please Select Minimum 2 Diamonds")
            return
        }
        if(selectedFlatRows.length > 4){
            alert("Only 4 diamonds allowed")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
        let inactive = false

       const selectedvalues = selectedFlatRows.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.id.toString())
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            if(finddata.stone_status === 0){
                inactive = true
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        if(inactive){
            // console.log(inactive,"inactive")
            setSwalErrorOpen(true)
            setAlertMessage("Please select only Active stone ...!!")
        }
        else{
            setDiamondSelected(selectedvalues)
        setComparePopup(true)
        setDiamondcount(diamondcount)
        setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
        setBuyPopUpTotal(temptotalourprice)
        }
      }
    const BuyDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
        let inactive = false
       const selectedvalues = selected.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.id.toString())
            if(finddata.country === "INDIA"){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            }
            if(finddata.stone_status === 0){
                inactive = true
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        if(inactive){
            // console.log(inactive,"inactive")
            setSwalErrorOpen(true)
            setAlertMessage("Please select only Active stone ...!!")
        }
        else{
            setDiamondSelected(selectedvalues)
            setBuyPopUp(true)
            setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
            setBuyPopUpTotal(temptotalourprice)
            setDiamondcount(diamondcount)
            // console.log(selectedvalues,"selectedvalues")
        }
      }
      const HoldDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
        let inactive = false
       const selectedvalues = selected.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.id.toString())
            if(finddata.country === "INDIA" && finddata.can_hold === 1){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            }
            if(finddata.stone_status === 0){
                inactive = true
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        if(inactive){
            // console.log(inactive,"inactive")
            setSwalErrorOpen(true)
            setAlertMessage("Please select only Active stone ...!!")
        }
        else{
            setDiamondSelected(selectedvalues)
            setHoldPopUp(true)
            setBuyPopUpInr(temptotalcarat>0?temptotalourprice/temptotalcarat:0)
            setBuyPopUpTotal(temptotalourprice)
            setDiamondcount(diamondcount)
            // console.log(selectedvalues,"selectedvalues")
        }
      }
    const DeleteWishlist = () => {
        if (selected.length === 0) {
            alert("Please Select a Item to Delete");
            return;
        }
        let ids = selected.map(value => {
            const finddata = StockAPI.find(element => element.id.toString() === value.toString())
            return finddata.Certi_NO
        })
        const userData = JSON.parse(localStorage.getItem("userData"));
        const finalData = {
            user_id: userData.CustomerId,
            id: ids,
        };
        axios({
            method: "DELETE",
            url: DELETE_WISHLIST,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        })
            .then((res) => {
                if (res && res.data) {
                    // setData(res.data)
                    // console.log(res.data,"res.data")
                    setUpdateWishlist(res.data);
                    setSelected([])
                    // setStockAPI(res.data)
                }
            })
            .catch((err) => {
            });
    };
    const ChangeView = () => {
        history.push({
            pathname: "/wishlist-grid",
            // state: StockAPI,
            state: {
                searchresult:FirstPage,
                // oldvalues:location.state.oldvalues
            }
            // state: decompress(res.data.ProductDetails),
        });
    };
    return (
        <>
            {StockAPI && StockAPI.length > 0 ? (
                <div
                    className={`card card-custom card-sticky gutter-b ${
                        StockAPI[0].diamond_type === "L" ? "labTab" : "natTab"
                    }`}
                >
                    <div className="card-header py-3 mb-3">
                        <div className="card-title align-items-start flex-column">
                            <span className="card-label font-weight-bolder text-dark">
                                {StockCount} {language.SEARCHLIST_DIAMONDSFOUND}{" "}
                            </span>
                            <span className="text-muted font-weight-bold font-size-sm">
                                {language.SEARCHLIST_TOTALDIAMONDSELECTED}: {selected.length}{" "}
                                {language.SEARCHLIST_DIAMONDS} | {language.SEARCHLIST_TOTALCARAT} :{totalcarat.toFixed(2)}{" "}
                                | Total {symbol === "₹" ? "INR" : symbol}/CT:{" "}
                                {symbol}
                                {totalinrcar.toFixed(2)} | {language.SEARCHLIST_TOTALPAYABLE}:{" "}
                                {symbol}
                                {totalourprice.toFixed(2)}
                            </span>
                        </div>
                        <div className="card-toolbar flex-md-nowrap">
                        {/* {selected.length >= 4 && !isprofilecomplete ?(
                                <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={() => profileSwalBuy() }> <BsLightningFill />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                            ):(
                                )} */}
                                <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={() => BuyDiamond(selected) }> <BsLightningFill />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                            {/* {selected.length >= 4 && !isprofilecomplete ?(
                                <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => profileSwalHold() }>{language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                            ):(
                                )} */}
                                <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => HoldDiamond(selected) }>{language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                                <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => CompareDiam(selected) }>{language.DIAMOND_COMPARISION_BTN}</button>
                            <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => DeleteWishlist()} > {language.WISHLIST_DELETETRACKSTONE} </button>
                            <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}/>  
                            <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={language}/>  
                            <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
                            <SwalError show={swalErrorOpen} message={alertMessage} onClose={() => setSwalErrorOpen(false)} responseOnOk={() => setSwalErrorOpen(false)}/> 
                            <CompareDiamond onHide={() => setComparePopup(false)} show={comparePopup} diamondselected={diamondselected} language={language} symbol={symbol}/>
                            {/* <button type="button" className="btn btn-icon btn-light-primary mr-2 text-nowrap"><FaThList /></button> */}
                            <button
                                type="button"
                                className="btn btn-icon btn-light-primary mr-2 text-nowrap"
                                onClick={(e) => ChangeView()}
                            >
                                <FaColumns />
                            </button>
                        </div>
                    </div>
                    <div className="card-body p-2">
                        <TableContainer
                            className="shadow-none custom-scrollbar"
                            component={Paper}
                        >
                            <Table
                                aria-label="collapsible table"
                                className="table table-hover mb-0 custom_datatable"
                            >
                                {/* <TableHead className="searchTableHeader">
                        <TableRow>
                            <TableCell />
                            <TableCell>StockID</TableCell>
                            <TableCell align="center">Location</TableCell>
                            <TableCell align="center">Media</TableCell>
                            <TableCell align="center">Shape</TableCell>
                            <TableCell align="center">Carat</TableCell>
                            <TableCell align="center">Color</TableCell>
                            <TableCell align="center">Clarity</TableCell>
                            <TableCell align="center">Cut</TableCell>
                            <TableCell align="center">Pol.</TableCell>
                            <TableCell align="center">Symm.</TableCell>
                            <TableCell align="center">Flou.</TableCell>
                            <TableCell align="center">Lab</TableCell>
                            <TableCell align="center">INR/CT</TableCell>
                            <TableCell align="center">Our Price</TableCell>
                            <TableCell align="center">Disc%</TableCell>
                            <TableCell align="center">Ratio</TableCell>
                            <TableCell align="center">Meas.</TableCell>
                            <TableCell align="center">Table</TableCell>
                            <TableCell align="center">Depth</TableCell>
                            <TableCell align="center">Certificate</TableCell>
                        </TableRow>
                    </TableHead> */}
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={StockCount}
                                    symbol={symbol}
                                    language={language}
                                    isActive={isActive}
                                />
                                <TableBody>
                                    {StockAPI
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(
                                                row.id
                                            );
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <Row
                                                    key={row.id}
                                                    row={row}
                                                    isItemSelected={isItemSelected}
                                                    selected={isItemSelected}
                                                    handleClick={handleClick}
                                                    labelId={labelId}
                                                    conversionrate={conversionrate}
                                                    symbol={symbol}
                                                    language={language}
                                                    totalourprice={buypopupctotal} totalinrcar={buypopupcinr} diamondselected={diamondselected} buypopup={buypopup} BuyDiamond={BuyDiamond} HoldDiamond={HoldDiamond}
                                                    StockAPI={StockAPI}
                                                    userinfo={userinfo}
                                                />
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={StockCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </div>
                </div>
            ) : (
                <div className="card custom-card gutter-b">
                    <div className="card-body d-flex align-items-center  justify-content-center" style={{height:'70vh'}}>
                       <div className="text-center">
                            <img src={toAbsoluteUrl("/media/General/tracklist-no.png")} className="img-fluid" alt="tracklist-no"/>
                            <div className="mt-5 font-size-h4">NO STONES AVAILABLE</div>
                            <div className="mt-3 text-muted">start listing diamonds</div>
                            <Link className="btn btn-primary px-8 mt-5" to={{ pathname:'/searchdiamond/white', option: "white" }}>Search Diamonds</Link>
                       </div>
                    </div>
                </div>
            )}
        </>
    );
};
