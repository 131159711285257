/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import {FaLongArrowAltDown,FaLongArrowAltUp} from "react-icons/fa"
import { Tracklist } from "./tracklist/Tracklist";
import axios from "axios";
// import { IVPCURRENCY } from "../../../../env_config";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NODE_API_URL,BASE_URL } from "../../../../env_config";
import Cookies from "js-cookie"

import { Skeleton } from "@material-ui/lab";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};


export const DashboardTracklist = ({
    symbol,
    conversionrate,
    itemInWishList,
    language,
    userinfo
}) => {
    const [data, setData] = useState([]);

    const [loader,setLoader]=useState(true)
    useEffect(() => {
        // let curr = "cur_inr"
        // if(data?.currency === "INR"){
        //   curr = "cur_inr"
        // }
        // if(data?.currency === "USD"){
        //   curr = "cur_usd"
        // }
        // if(data?.currency === "AUD"){
        //   curr = "cur_aud"
        // }
        // if(data?.currency === "CAD"){
        //   curr = "cur_cad"
        // }
        // if(data?.currency === "HKD"){
        //   curr = "cur_hkd"
        // }
        // if(data?.currency === "CNY"){
        //   curr = "cur_cny"
        // }
        // if(data?.currency === "EUR"){
        //   curr = "cur_eur"
        // }
        // if(data?.currency === "GBP"){
        //   curr = "cur_gbp"
        // }
        // if(data?.currency === "NZD"){
        //   curr = "cur_nzd"
        // }
        // if(data?.currency === "JPY"){
        //   curr = "cur_jpy" 
        // }
        // if(data?.currency === "CHF"){
        //   curr = "cur_chf"
        // }
        // if(data?.currency === "BND"){
        //     curr = "cur_bnd"
        // }
        // if(data?.currency === "SGD"){
        //     curr = "cur_sgd"
        // }
        // if(data?.currency === "THB"){
        //     curr = "cur_thb"
        // }
        // if(data?.currency === "MYR"){
        //     curr = "cur_myr"
        // }
        // if(data?.currency === "DKK"){
        //     curr = "cur_dkk"
        // }
        // if(data?.currency === "SEK"){
        //     curr = "cur_sek"
        // }
        axios({
            method: "GET",
            url: `${NODE_API_URL}/IVP/fetch-tracklist-expand-stone-wise`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
        })
            .then((res) => {
                if (res && res.data && res.data.data) {
                    setLoader(false)
                    setData(res.data.data);
                    // setCurrency(res.data.data.currency);
                }
            })
            .catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })

            // axios({
            //     method: "POST",
            //     url: IVPCURRENCY,
            //     headers: { "Content-Type": "application/json" },
            // }).then(response => {
            //     if(response && response.data){
            //         // console.log(response.data.data[0])
            //         response.data.data[0].cur_usd = 1
            //         const currentCurrency = response.data.data[0][curr];
            //         setCurrentCurrRate(Math.round(currentCurrency*100)/100)
            //         // console.log(curr,currentCurrency)
            //     }
            // })
    }, []);

    return (
        <>
            <div className={`card card-custom gutter-b bg-white`}>
                <div className="card-body dasboardSearch">
                    <div className="d-flex justify-content-between ">
                        <div>
                            <span className="small_title">{language.DASHBOARD_MYTRACKLIST}</span>
                        </div>
                        {data.length < 0? (
                            " "
                        ) : (
                            <Link to={{ pathname:'/tracklist' }}>
                                {language.DASHBOARD_MYTRACKLIST_SEEALL}
                            </Link>
                        )}
                    </div>
                    {
                        !loader ? 
                        <>
                            {data.length < 0 ? (
                                <div className="d-flex flex-nowrap tracklistdata justify-content-center align-items-center my-10 pb-4">
                                    NO Records Found
                                </div>
                            ) : (
                                <PerfectScrollbar
                                    options={perfectScrollbarOptions}
                                    className="scroll tracklistdata"
                                >
                                    {/* <div className=" custom-scrollbar"> */}
                                        {data
                                            ? data.sort((a, b) => {
                                                return new Date(b.tracklisted_date) - new Date(a.tracklisted_date);
                                            }).slice(0, 5).map((val) => {
                                                return (
                                                    <Tracklist
                                                        key={val.id}
                                                        symbol={symbol}
                                                        conversionrate={conversionrate}
                                                        // currentCurrRate={currentCurrRate}
                                                        userinfo={userinfo}
                                                        {...val}
                                                        
                                                    />
                                                );
                                            })
                                            : <></>}
                                    {/* </div> */}
                                </PerfectScrollbar>
        
                            )}
                        </>
                    :
                    <>
                        {Array.from(Array(3), (e, i) => {
                            return (
                              <div className="d-flex mt-5 justify-content-between align-items-end border-bottom pb-3 px-3" key={i}>
                                <div>
                                  <div className="d-flex align-items-center">
                                    <Skeleton variant="circle" width={15} height={15} />
                                    <span className="mr-10 ml-2"><Skeleton variant="text" width={100} /></span>
                                    <span><Skeleton variant="text" width={100} /></span>
                                  </div>
                                  <Skeleton variant="text" width={280} />
                                </div>
                                <Skeleton variant="text" width={80} />
                              </div>
                            );
                          })}
                    </>
                    }
                </div>
            </div>
        </>
    );
};
