import React, { useState, useEffect } from "react";
import { IoCloseOutline, IoShareOutline } from "react-icons/io5";
import { Modal, OverlayTrigger,Tooltip } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { ImageSharePopup } from "./ImageSharePopup";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg"
import axios from "axios";
export const VideoExpandPopupCommon = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (event) => {
        if (event.ctrlKey || event.metaKey) {
            window.open(
                props.data.video
            );
        } else {
            setShow(true);
        }
    };

    const [frameloaded, setFrameLoaded] = useState(false);
    const [showimage, setShowImage] = useState(false);
    const [shareurl, setShareURL] = useState("");

    const FrameFunction = () => {
        setFrameLoaded(true);
    };

    const copyurl = (diamonddata) => {
        if (diamonddata.ext_status === "Success") {
            axios({
                method: "POST",
                url: `https://api.dia360.cloud/api/admin/revert-public-url`,
                headers: {
                    "Content-Type": "application/json",
                    "x-api-key": "26eca0a8-1981-11ee-be56-0242ac120002",
                },
                data: {
                    ext_video_id: diamonddata.ext_video_id.toString(),
                    client_id: props.userinfo.id.toString(),
                },
            }).then(function(response) {
                if (response && response.data && response.data.url) {
                    setShareURL(response.data.url);
                    navigator.clipboard.writeText(response.data.url);
                    setShowImage(true);
                }
            });
        } else if (diamonddata.video) {
            const videoUrl = props.data.video;
            setShareURL(videoUrl);
            navigator.clipboard.writeText(videoUrl);
            setShowImage(true);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && show) {
                handleClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [show]);

    const GetVideoUrl = (data) => {
        if (data.ext_status === "Success") {
            return data.m_video_path;
        } else if (data.video) {
            return data.video
        }
        return "";
    };

    return (
        <>
            {props.data.video ? (
                <div>
                    <li className="img_thumb cursor-pointer" onClick={(e) => handleShow(e)}>
                        <SVG  width="40" src={toAbsoluteUrl("/media/General/videoIcon.svg")} />
                        {/* <img  alt="img thumb" src="https://assets.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/> */}
                    </li>

                    <ImageSharePopup
                        show={showimage}
                        onClose={() => setShowImage(false)}
                        src={shareurl}
                    />
                    <Modal
                        className="p-0 video-modal"
                        show={show}
                        onHide={handleClose}
                        size="xl"
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-video"
                    >
                        {/* <div className="modal-header-custom">
                            <OverlayTrigger placement="bottom" overlay={ <Tooltip id="tooltip"> Share </Tooltip> } >
                                <IoShareOutline  className="modal-icon" onClick={() => copyurl(props.data)} />
                            </OverlayTrigger>
                            <OverlayTrigger placement="bottom" overlay={ <Tooltip id="tooltip"> Close </Tooltip> } >
                                <IoCloseOutline  className="modal-icon" onClick={handleClose} />
                            </OverlayTrigger>
                        </div> */}
                        <Modal.Body className="modal-body-custom flex-column">
                            {!frameloaded && (
                                <div className="loader-container">
                                    <CircularProgress />
                                    <h6>Please Wait...</h6>
                                </div>
                            )}
                            {frameloaded ? 
                                <div className="pl-2 p-0 mt-10 mb-5 text-left">
                                    <div className={`mb-1 ${props.data.diamondType === "N" ? "natTab" : "labTab"}`}>
                                        <span
                                            className={`${
                                                props.data.diamondType === "N"
                                                    ? props.data.isfancy
                                                        ? "F"
                                                        : "W"
                                                    : props.data.isfancy
                                                    ? "LF"
                                                    : "L"
                                            }_diamond mr-3`}
                                        >
                                            {props.data.diamondType === "N"
                                                ? props.data.isfancy
                                                    ? "NF"
                                                    : "N"
                                                : props.data.isfancy
                                                ? "LF"
                                                : "L"}
                                        </span>
                                        {props.data.diamondType === "N" ? "N" : "L"}-{props.data.stockId}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className=" mx-2"
                                            href={
                                                props.data.lab === "IGI"
                                                    ? `https://www.igi.org/verify-your-report/?r=LG${props.data.certificate}`
                                                    : props.data.lab === "GIA"
                                                    ? `https://www.gia.edu/report-check?reportno=${props.data.certificate}`
                                                    : props.data.lab === "HRD"
                                                    ? `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${props.data.certificate}`
                                                    : props.data.lab === "GCAL"
                                                    ? `https://www.gcalusa.com/certificate-search.html?certificate_id=${props.data.certificate}`
                                                    : props.data.Certi_link
                                            }
                                        >
                                            {props.data.lab}-{props.data.certificate}
                                        </a>
                                        
                                    </div>

                                    <span className="text-dark font-size-h3 font-weight-bold d-block">
                                        {props.data.shape}{" "}
                                        {props.data.carat ? parseFloat(props.data.carat).toFixed(2) :""}{" "}
                                        {props.data.color}{" "}
                                        {props.data.clarity}{" "}{props.data.cut}{" "}{props.data.polish}{" "}{props.data.symmetry}{" "}
                                        {props.data.fluorescence}
                                    </span>
                                </div>
                                :null
                            }
                            {props.data.video && (
                                <iframe
                                    id="iframedata"
                                    title="Video"
                                    src={props.data.video}
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    onLoad={FrameFunction}
                                    style={{ display: frameloaded ? "block" : "none" }}
                                ></iframe>
                            )}
                        </Modal.Body>
                        <Modal.Footer className="p-3">
                            <button className="btn btn-primary" onClick={() => copyurl(props.data)}>share</button>
                            <button className="btn btn-secondary" onClick={handleClose}>Close</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            ) : ( null )}
        </>
    );
};
